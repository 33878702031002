<template>
    <div class="huipao-ai-container">
        <RsHuipaoAigc/>
    </div>
</template>

<script>
import RsHuipaoAigc from "@/views/huipao-ai/rs-huipao-aigc.vue";

export default {
    components: {RsHuipaoAigc}
}
</script>

<style scoped lang="less">
.huipao-ai-container {
  //    渐变色从左上角到右下角 左上角的颜色是#E0EBFD 中间的颜色是#A8D8EF 右下角的颜色是#91EAE4，整体的透明度是0.4
  background: linear-gradient(135deg, rgba(224, 235, 253, 0.4) 0%, rgba(168, 216, 239, 0.4) 50%, rgba(145, 234, 228, 0.4) 100%);
  // 最大高度
  min-height: 100vh;
}

</style>
