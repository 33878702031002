<template>
    <div class="rs-huipao-container-base">
        <div class="rs-huipao-container">
            <div class="huipao-header-logo">
                <img src="/rshuipao-logo.png"/>
                <div class="title">人生会跑 AIGC</div>
                <div class="sub-title">首个由AI支持的抑郁康复领域AIGC模型</div>
            </div>
            <div class="huipao-template-question-container">
                <!--            遍历templateQuestionData 标题居中 16px黑色，问题是10圆角 背景色是#F8F9FD的卡片-->
                <div class="template-question" v-for="(questionData,index) in templateQuestionDataList" :key="index">
                    <div class="title">
                        {{ questionData.title }}
                    </div>
                    <div class="question-card">
                        {{ questionData.question }}
                    </div>
                </div>
            </div>
            <!--            聊天框-->
            <div class="huipao-ai-chat-container">
                <!--        目前有三种类型，自己发送，系统提示，对方发送        -->
                <div class="chat-item" v-for="(chatItem,index) in chatDataList" :key="index">
                    <div v-if="chatItem.type === 'self'" class="chat-item-self">
                        <div class="chat-item-self-content">
                            {{ chatItem.content }}
                        </div>
                    </div>
                    <div v-if="chatItem.type === 'other'"
                         :class="['chat-item-other',chatItem.isInStream===true ? 'chat-item-other-in-stream' : '']">
                        <div :class="['chat-item-other-content',chatItem.isInStream===true ? 'in-stream' : '',
                        chatItem.content.length !== 0 ? 'has-content' : ''
                        ]">
                            {{ chatItem.content }}
                        </div>
                    </div>
                    <div v-if="chatItem.type === 'system-notice'" class="chat-item-system-notice">
                        <!--                        绿色对勾-->
                        <img :src="greenApprovalSvg" class="green-approval-icon"/>
                        <div class="chat-item-system-content">
                            {{ chatItem.content }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        悬浮输入框 右侧发送按钮 圆角40px 背景色#f8f9fd-->
        <div class="huipao-input-container" v-if="isShowChatInput">
            <van-field
                    placeholder=""
                    clearable
                    maxlength="200"
                    :rows="1"
                    :autosize="{ minRows: 1, maxRows: 3 }"
                    type="textarea"
                    v-model="msg"
                    class="huipao-input"
            />
            <div class="send-btn" @click="sendMsg">
                发送
            </div>
        </div>
    </div>
</template>
<script>
import greenApprovalIcon from "@/assets/images/green_approval.svg";
import axios from "axios";

export default {
    components: {},
    data() {
        return {
            greenApprovalSvg: greenApprovalIcon,
            msg: "",
            templateQuestionDataList: [
                {
                    "title": "🤔 面对家长询问当日情况",
                    "question": "妈妈询问：“今天孩子在会跑情况如何？老师们都开展了哪些工作？”请给出在微信群内的回答，语气友好且亲切"
                },
                {
                    "title": "🙌 生成今天的实施报告",
                    "question": "请给出乐乐今天的实施报告，要详细且具体"
                },
                {
                    "title": "💞给出家庭边界指导",
                    "question": "爸爸描述：“孩子今天又黑白颠倒，不想去工作室了，现在正在床上准备绝食”，请根据最近的表现和目前的情况给出家庭边界指导"
                }
            ],
            chatDataList: [
                // 自己 爸爸描述：“孩子今天又黑白颠倒，不想去工作室了，现在正在床上准备绝食”，请根据最近的表现和目前的情况给出家庭边界指导
                // 系统提示：已获取：乐乐的最近表现、已获取：历史家庭状况、正在为你生成答案
                // 回复：OK已收到
                // {
                //     "type": "self",
                //     "content": "爸爸描述：“孩子今天又黑白颠倒，不想去工作室了，现在正在床上准备绝食”，请根据最近的表现和目前的情况给出家庭边界指导"
                // },
                // {
                //     "type": "system-notice",
                //     "content": "已获取：乐乐的最近表现"
                // },
                // {
                //     "type": "system-notice",
                //     "content": "已获取：历史家庭状况"
                // },
                // {
                //     "type": "system-notice",
                //     "content": "正在为你生成答案"
                // }
            ],
            isShowChatInput: true,
        }
    },
    created() {
        // this.fetchData();
    },
    methods: {
        async fetchData(msg) {
            try {
                var lastItem = this.chatDataList[this.chatDataList.length - 1];
                if (!(lastItem && lastItem.isInStream === true)) {
                    this.chatDataList.push({
                        "type": "other",
                        "content": "",
                        "isInStream": true,
                    });
                    lastItem = this.chatDataList[this.chatDataList.length - 1];
                }
                const response = await fetch("/api/aigc/aigc?msg=" + msg);
                if (!response.ok) {
                    this.isShowChatInput = true;
                    lastItem.isInStream = false;
                    lastItem.content = "网络异常";
                    throw new Error("网络异常");
                }
                // 获取流
                const reader = response.body.getReader();
                // 读取流
                reader.read().then(function processText({done, value}) {
                    if (done) {
                        console.log("读取完成");
                        this.isShowChatInput = true;
                        lastItem.isInStream = false;
                        return;
                    } else {
                        this.isShowChatInput = false;
                    }
                    console.log("done", done);
                    console.log("value", value);
                    var content = new TextDecoder("utf-8").decode(value);
                    if (lastItem && lastItem.isInStream === true) {
                        lastItem.content = content;
                    } else {
                        this.chatDataList.push({
                            "type": "other",
                            "content": content,
                            "isInStream": true,
                        });
                        lastItem = this.chatDataList[this.chatDataList.length - 1];
                    }
                    reader.read().then(processText.bind(this));
                }.bind(this));

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        async sendMsg() {
            const msg = this.msg;
            if (!msg) {
                return;
            }
            // 发送消息
            this.isShowChatInput = false;
            this.chatDataList.push({
                "type": "self",
                "content": msg,
            });
            const customerName = "客户";
            const content = "已收到您的请求，正在为您准备“" + customerName + "”的上下文信息。";
            // 先push一个other，isInStream为true，然后在1秒内更新内容
            this.chatDataList.push({
                "type": "other",
                "content": "",
                "isInStream": true,
            });
            await new Promise(resolve => setTimeout(resolve, 2000));
            // 开启一个定时器，逐个字符更新内容，模拟GPT的感觉
            for (let i = 0; i < content.length; i++) {
                this.chatDataList[this.chatDataList.length - 1].content = content.slice(0, i + 1);
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            // 设置isInStream为false
            this.chatDataList[this.chatDataList.length - 1].isInStream = false;
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.chatDataList.push({
                "type": "system-notice",
                "content": "已获取：" + customerName + "的最近表现"
            });
            await new Promise(resolve => setTimeout(resolve, 1500));
            this.chatDataList.push({
                "type": "system-notice",
                "content": "已获取：历史家庭状况"
            });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.chatDataList.push({
                "type": "system-notice",
                "content": "正在为你生成答案"
            });
            await new Promise(resolve => setTimeout(resolve, 500));
            // 请求fetchData
            await this.fetchData(msg);
            this.msg = "";
        }
    }
}
</script>
<style scoped lang="less">

.rs-huipao-container-base {
  .huipao-input-container {
    position: fixed;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    background-color: #f8f9fd;
    display: flex;
    border-radius: 40px;
    align-items: center;
    padding: 5px 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

    .huipao-input {
      flex: 1;
      padding-left: 10px;
      padding-right: 10px;
      background-color: transparent;
      color: #000000;
      font-size: 16px;
    }

    .send-btn {
      margin-right: 15px;
      color: #1C92D2;
      font-size: 14px;
    }
  }

  .rs-huipao-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 5rem;

    // 整个聊天框
    .huipao-ai-chat-container {
      // 聊天框
      .chat-item {
        .chat-item-self {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-top: 10px;

          .chat-item-self-content {
            // 渐变色左上角到右下角 左上角#2870EA 右下角#1B4AEF
            background: linear-gradient(135deg, #2870EA 0%, #1B4AEF 100%);
            color: #ffffff;
            border-radius: 10px;
            padding: 8px 10px 12px 10px;
            font-size: 16px;
            // 底部阴影
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
          }
        }

        .chat-item-other {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .chat-item-other-content {
            background-color: #f8f9fd;
            color: #000000;
            border-radius: 10px;
            padding: 8px 10px 12px 10px;
            font-size: 16px;
            // 允许\n换行
            white-space: break-spaces;
            // 行间距
            line-height: 1.5;

            // 当同时出现in-stream这个类时，在文字最后加一个闪烁的光标
            &.in-stream::after {
              content: "";
              display: inline-block;
              width: 5px;
              height: 1.2em;
              margin-bottom: -3px;
              margin-left: -5px;
              background-color: #000;
              animation: blink 1s infinite;
            }

            &.has-content::after {
              margin-left: 4px;
            }

            // 光标闪烁动画 0%时隐藏，100%时显示
            @keyframes blink {
              0%, 50% {
                opacity: 0;
              }
              51%, 100% {
                opacity: 1;
              }
            }
          }
        }

        // 系统提示 居左，子布局居左 水平布局
        .chat-item-system-notice {
          display: flex;
          flex-direction: row;
          align-items: center;
          //  垂直居中
          justify-content: flex-start;
          margin-top: 10px;
          margin-bottom: 10px;
          // 出现的时候从下往上滑动，渐渐显示
          animation: slide-up 1s ease-in-out;

          // 滑动动画
          @keyframes slide-up {
            0% {
              transform: translateY(100%);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }

          .chat-item-system-content {
            font-size: 14px;
            color: #000000;
            margin-bottom: 3px;
            margin-left: 5px;
          }
        }

      }
    }

    .huipao-header-logo {
      // 子部件左右居中
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;

      .title {
        font-size: 36px;
        font-weight: bold;
        color: #000000;
        margin-top: 10px;
      }

      .sub-title {
        font-size: 18px;
        color: #000000;
        margin-top: 10px;
      }
    }

    .huipao-template-question-container {

      .template-question {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;

        .title {
          font-size: 16px;
          color: #000000;
        }

        .question-card {
          background-color: #F8F9FD;
          border-radius: 10px;
          padding: 10px;
          color: #000000;
          font-size: 16px;
          margin-top: 15px;
        }
      }
    }
  }


}

</style>
